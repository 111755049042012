<template>
  <b-modal
    ref="modalUpsertTemplate"
    :visible="isUpsertTemplateOpen"
    @hide="closeModal"
    size="lg"
    :busy="busy"
    :title="`${templateData.isNew ? 'Create' : 'Update'} Monday Contact Template`"
    :ok-title="templateData.isNew ? 'Create' : 'Update'"
    centered
    @ok="submitTemplate"
  >
    <b-row>
      <b-col cols="12" md="7" lg="7">
        <b-form-group label="Template Name" label-for="template-name">
          <b-form-input required- autofocus id="template-name" placeholder="Placement : Intro Email" v-model="templateData.name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="5" lg="5">
        <b-form-group label="Template Label" label-for="template-label">
          <b-form-input required id="template-label" placeholder="Intro" v-model="templateData.label" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Type" label-for="template-type">
          <b-form-select
            id="template-type"
            required
            :options="[
              { value: 'email', text: 'Email' },
              { value: 'sms', text: 'SMS' },
            ]"
            v-model="templateData.type"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Is Enabled?" label-for="template-enabled">
          <b-form-select
            id="template-enabled"
            required
            :options="[
              { value: true, text: 'Yes' },
              { value: false, text: 'No' },
            ]"
            v-model="templateData.enabled"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Is Partial?" label-for="template-partial">
          <b-form-select
            id="template-partial"
            required
            :options="[
              { value: true, text: 'Yes' },
              { value: false, text: 'No' },
            ]"
            v-model="templateData.partial"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="12">
        <b-form-group label="Template Subject" label-for="template-subject">
          <b-form-input id="template-subject" placeholder="Placement Intro Email" v-model="templateData.subject" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="12">
        <b-form-group label="Template Body" label-for="template-body">
          <quill-editor
            v-if="templateData.type === 'email'"
            id="template-body"
            v-model="templateData.body"
            :options="{ theme: 'snow', syntax: false }"
            style="min-height: 150px"
          ></quill-editor>
          <b-form-textarea
            v-if="templateData.type === 'sms'"
            required
            id="template-body"
            :placeholder="`Hi there, {{ student.fullName }}`"
            style="min-height: 150px"
            v-model="templateData.body"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BFormSelectOption, BModal, BRow, VBModal } from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  name: 'UpsertMondayTemplate',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    quillEditor,
  },
  directives: { 'b-modal': VBModal },
  props: {
    isUpsertTemplateOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    upsertData: {
      type: Object,
      default: undefined,
    },
  },
  data: () => {
    return {
      templateData: {},
      busy: false,
    };
  },
  watch: {
    '$props.isUpsertTemplateOpen'(val) {
      console.log(val);
    },
    '$props.upsertData'(val) {
      this.templateData = val;
    },
  },
  mounted() {
    if (this.$props.upsertData) {
      this.templateData = this.$props.upsertData;
    }
  },
  methods: {
    closeModal() {
      this.busy = false;
      // this.$emit('update:upsert-data', { isNew: true });
      this.$emit('update:is-upsert-template-open', false);
    },
    submitTemplate() {
      const pl = { ...this.templateData };
      const { isNew } = pl;
      delete pl.isNew;

      let prom;

      this.busy = true;
      if (isNew) {
        prom = store.dispatch('placementStoreModule/createTemplate', { ...pl, module: 'placement' });
      } else {
        prom = store.dispatch(`placementStoreModule/updateTemplate`, {
          id: this.templateData.id,
          templateData: pl,
        });
      }

      prom
        .then((a) => {
          console.log(`${isNew ? 'created' : 'updated'}`, a);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Successfully ${isNew ? 'created' : 'update'} template`,
              variant: 'success',
            },
          });
          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();
        })
        .catch((err) => {
          console.error(`failed tp ${isNew ? 'create' : 'update'} template`);
          console.error(err);

          this.busy = false;
          this.$emit('refetch-data');

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Failed to ${isNew ? 'created' : 'update'} template`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response ? err.response.data.message : err.message,
            },
          });
        });
    },
  },
  setup(props) {
    const PLACEMENT_STORE_NAME = 'placementStoreModule';

    return {};
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
